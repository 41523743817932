<template>
    <v-container class="my-10">
        <div class="d-flex align-center">
          <v-icon normal left color="purple">fa fa-user-check</v-icon>
          <h2 class="main-heading primary--text">
            {{ $t(`Admin.newsletter.mainHeading`) }}
          </h2>
        </div>
        <base-card class="fill-height">
            <template #content>
                <v-card-text class="flex-col-between fill-height">
                    <v-data-table
                        :headers="headers"
                        :items="items"
                        :items-per-page="8"
                        :loading="getSubscriptionsStatus_Pending"
                        :loading-text="$t(`Admin.dataTable.loadingText`)"
                        :no-data-text="$t(`Admin.dataTable.noDataText`)"
                        :no-results-text="$t(`Admin.dataTable.noResultsText`)"
                        :page.sync="page"
                        hide-default-footer
                        disable-sort
                    >
                        <template v-slot:top>
                            <v-row class="mb-4">
                                <v-col cols="12" class="d-flex justify-end">
                                    <form @submit.prevent="addEmail()">
                                        <v-text-field
                                            v-model="emailToAdd"
                                            outlined
                                            prepend-icon="fa fa-user-plus"
                                            :error-messages="emailErrors"
                                            label="E-mail"
                                            required
                                            style="max-width: 400px"
                                            @input="handleAddEmailTouch()"
                                            @blur="$v.emailToAdd.$touch()"
                                        >
                                            <template #append>
                                                <v-btn
                                                    depressed
                                                    dense
                                                    type="submit"
                                                    color="primary"
                                                    :loading="addStatus_Pending"
                                                    :disabled="
                                                        $v.emailToAdd.$invalid
                                                    "
                                                >
                                                    <v-icon
                                                        size="14"
                                                        class="mr-2"
                                                    >
                                                        fa-solid fa-plus
                                                    </v-icon>
                                                    {{
                                                        $t(
                                                            `Admin.newsletter.createNew`
                                                        )
                                                    }}
                                                </v-btn>
                                            </template>
                                        </v-text-field>
                                    </form>
                                </v-col>
                            </v-row>
                        </template>

                        <template #[`item.actions`]="{ item }">
                            <dropdown-menu
                                :is-text="true"
                                :can-close="true"
                                icon="fas fa-ellipsis-v"
                            >
                                <template #listItems>
                                    <v-list>
                                        <v-list-item
                                            class="item-hover px-0"
                                            @click="editItem(item)"
                                        >
                                            <v-btn text color="accent darken-4">
                                                <v-icon size="12" class="mr-2">
                                                    fa-solid fa-pen
                                                </v-icon>
                                                <span class="table-menu-item">{{
                                                    $t(
                                                        `Admin.dataTable.tableActions.edit`
                                                    )
                                                }}</span>
                                            </v-btn>
                                        </v-list-item>
                                        <v-list-item
                                            class="item-hover px-0"
                                            @click="deleteItem(item)"
                                        >
                                            <v-btn text color="error lighten-1">
                                                <v-icon size="12" class="mr-2">
                                                    fa-solid fa-trash
                                                </v-icon>
                                                <span class="table-menu-item">{{
                                                    $t(
                                                        `Admin.dataTable.tableActions.delete`
                                                    )
                                                }}</span>
                                            </v-btn>
                                        </v-list-item>
                                    </v-list>
                                </template>
                            </dropdown-menu>
                        </template>
                    </v-data-table>
                    <div v-if="pageCount > 1">
                        <v-divider class="mb-4"></v-divider>

                        <div class="text-center table-pagination">
                            <v-pagination
                                v-model="page"
                                class="table-pagination"
                                :length="pageCount"
                                elevation="0"
                                :total-visible="5"
                            ></v-pagination>
                        </div>
                    </div>
                </v-card-text>
            </template>
        </base-card>
        <confirm-modal :open="deleteModal" :close.sync="deleteModal">
            <template #content>
                <span class="modal-content">
                    {{ $t(`Admin.newsletter.deleteTxt`) }}
                </span>
            </template>
            <template #action>
                <v-btn
                    depressed
                    dense
                    color="error lighten1"
                    class="mr-2"
                    :loading="deleteStatus_Pending"
                    @click="confirmDelete()"
                >
                    {{ $t(`Admin.modal.confirm`) }}
                </v-btn>
            </template>
        </confirm-modal>

        <v-dialog v-model="editModal" width="400">
            <v-card>
                <v-card-title>
                    <h4 class="modal__heading">
                        {{ $t(`Admin.newsletter.editModalTitle`) }}
                    </h4>
                </v-card-title>

                <v-card-text>
                    <v-text-field
                        v-model="emailToUpdate"
                        outlined
                        :error-messages="updateErrors"
                        label="E-mail"
                        required
                        style="max-width: 400px"
                        @input="handleUpdateTouch()"
                        @blur="$v.emailToUpdate.$touch()"
                    ></v-text-field>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        depressed
                        dense
                        text
                        color="primary darken4"
                        class="mr-2"
                        @click="editModal = false"
                    >
                        {{ $t(`Admin.modal.cancel`) }}
                    </v-btn>
                    <v-btn
                        depressed
                        dense
                        color="primary lighten1"
                        class="mr-2"
                        :loading="editStatus_Pending"
                        :disabled="$v.emailToUpdate.$invalid"
                        @click="confirmEdit()"
                    >
                        {{ $t(`Admin.modal.confirm`) }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { apiStatus } from "@/api/constants/apiStatus";
import { apiStatusComputed } from "@/api/helpers/computedApiStatus";
import { withAsync } from "@/helpers/withAsync";
import {
    getSubscriptions,
    deleteSubscription,
    addSubscription,
    editSubscription,
} from "@/api/adminApi.js";
import BaseCard from "@/components/base/BaseCard.vue";
import DropdownMenu from "./components/DropdownMenu.vue";

import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

export default {
    name: "NewsletterSubscriptions",

    mixins: [validationMixin],

    validations: {
        emailToAdd: { required, email },
        emailToUpdate: { required, email },
    },

    components: {
        BaseCard,
        DropdownMenu,
        ConfirmModal: () => import("@/components/ConfirmModal.vue"),
    },

    data() {
        return {
            items: [],
            page: 1,
            pageCount: 0,
            deleteModal: false,
            editModal: false,

            emailToAdd: "",
            emailToUpdate: "",

            itemToDelete: {},
            itemToEdit: {},

            getSubscriptionsStatus: apiStatus.Idle,
            deleteStatus: apiStatus.Idle,
            editStatus: apiStatus.Idle,
            addStatus: apiStatus.Idle,

            addEmailError: "",
            updateEmailError: "",
        };
    },

    computed: {
        emailErrors() {
            const errors = [];
            if (!this.$v.emailToAdd.$dirty) return errors;
            this.addStatus_Error && errors.push(this.addEmailError);
            !this.$v.emailToAdd.email && errors.push("Must be valid e-mail");
            !this.$v.emailToAdd.required && errors.push("E-mail is required");
            return errors;
        },

        updateErrors() {
            const errors = [];
            if (!this.$v.emailToUpdate.$dirty) return errors;
            this.editStatus_Error && errors.push(this.updateEmailError);
            !this.$v.emailToUpdate.email && errors.push("Must be valid e-mail");
            !this.$v.emailToUpdate.required &&
                errors.push("E-mail is required");
            return errors;
        },
        ...apiStatusComputed([
            "getSubscriptionsStatus",
            "deleteStatus",
            "editStatus",
            "addStatus",
        ]),
        headers() {
            return [
                { text: "Id", value: "id", width: "10%" },
                {
                    text: this.$t(`Admin.dataTable.headers.subscription`),
                    value: "email",
                    width: "80%",
                },
                {
                    text: this.$t(`Admin.dataTable.headers.actions`),
                    value: "actions",
                    sortable: false,
                    width: "10%",
                },
            ];
        },
    },

    methods: {
        async getAllSubscriptions() {
            this.getSubscriptionsStatus = apiStatus.Pending;
            const payload = {
                page: this.page,
                items: 7,
            };

            const { response, error } = await withAsync(
                getSubscriptions,
                payload
            );

            if (error) {
                this.getSubscriptionsStatus = apiStatus.Error;
                return;
            }

            if (response.data.data.length <= 0 && this.page > 1) {
                this.page -= 1;
                return;
            }
            this.items = response.data.data;
            this.pageCount = response.data.last_page;
            this.getSubscriptionsStatus = apiStatus.Success;
        },

        async addEmail() {
            this.addStatus = apiStatus.Pending;
            const payload = {
                email: this.emailToAdd,
            };
            const { response, error } = await withAsync(
                addSubscription,
                payload
            );

            if (error) {
                this.addEmailError =
                    error.response.data.errors.email[0] ||
                    "Something went wrong";
                this.addStatus = apiStatus.Error;
                return;
            }

            this.addStatus = apiStatus.Success;
            this.emailToAdd = "";
            this.$v.emailToAdd.$reset();
            this.getAllSubscriptions();
        },

        handleAddEmailTouch() {
            this.$v.emailToAdd.$touch();
            this.addStatus = apiStatus.Idle;
        },

        editItem(item) {
            this.itemToEdit = item;
            this.editModal = true;
        },

        async confirmEdit() {
            this.editStatus = apiStatus.Pending;
            const payload = {
                id: this.itemToEdit.id,
                data: {
                    email: this.emailToUpdate,
                },
            };
            const { response, error } = await withAsync(
                editSubscription,
                payload
            );

            if (error) {
                this.updateEmailError =
                    error.response.data.errors.email[0] ||
                    "Something went wrong";
                this.editStatus = apiStatus.Error;
                return;
            }

            this.editStatus = apiStatus.Success;
            this.editModal = false;
            this.getAllSubscriptions();
        },

        handleUpdateTouch() {
            this.$v.emailToUpdate.$touch();
            this.editStatus = apiStatus.Idle;
        },

        deleteItem(item) {
            this.itemToDelete = item;
            this.deleteModal = true;
        },

        async confirmDelete() {
            this.deleteStatus = apiStatus.Pending;
            const payload = {
                id: this.itemToDelete.id,
            };
            const { response, error } = await withAsync(
                deleteSubscription,
                payload
            );

            if (error) {
                this.deleteStatus = apiStatus.Error;
                return;
            }

            this.deleteStatus = apiStatus.Success;
            this.deleteModal = false;
            this.getAllSubscriptions();
        },
    },

    watch: {
        page(newVal) {
            this.getAllSubscriptions();
        },

        editModal(newVal) {
            if (newVal) {
                this.emailToUpdate = this.itemToEdit.email;
                this.emailToAdd = "";
                this.$v.emailToAdd.$reset();
            } else {
                this.emailToUpdate = "";
                this.$v.emailToUpdate.$reset();
            }
        },

        deleteModal(newVal) {},
    },

    created() {
        this.getAllSubscriptions();
    },
};
</script>

<style>
/*center text-field appended*/
.v-text-field .v-input__prepend-inner,
.v-text-field .v-input__append-inner {
    align-self: center !important;
}
</style>
